<div class="p-xcc my-xcc-sm bg-white rounded-md font-primary text-grey-900">
  <form [formGroup]="formGroup" id="xcc-parent-account-form">
    <h3 class="panel-title xcc-header-spacing">Create Your Account</h3>
    <xcc-input
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="text"
      autocapitalize="on"
      autocomplete="given-name"
      label="Student First Name"
      controlName="studentName"
      [onBlur]="onBlurStudentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{ required: 'First name is required.', pattern: 'Use letters only.' }"
    >
    </xcc-input>
    <xcc-input
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="email"
      label="Student Email Address"
      controlName="email"
      [onBlur]="onBlurStudentEmail"
      [formGroup]="formGroup"
      [infoIcon]="true"
      [infoTooltip]="emailInfoMessage"
      [errorMessage]="{ required: 'Email is required.', pattern: 'Enter a valid email address.', emailDomain: 'Invalid email domain' }"
    >
    </xcc-input>

    <p class="text-xs leading-disclaimer" *ngIf="userExists && !iAmParent">
      {{ userExistInfoText }}
    </p>

    <xcc-input
      *ngIf="iAmParent || (!userExists && !iAmParent)"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="on"
      label="Confirm Student Email Address"
      controlName="confirmEmail"
      [onBlur]=""
      [formGroup]="formGroup"
      [infoIcon]="false"
      [infoTooltip]=""
      [errorMessage]="{ required: 'Reenter your email address.', pattern: 'Email doesn\'t match.', notMatch: 'Email doesn\'t match.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="!userExists && !iAmParent"
      parentClass="flex flex-col items-start items-stretch"
      isPasswordInput="true"
      type="password"
      autocapitalize="off"
      autocomplete="off"
      label="Student Password"
      controlName="password"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{required: 'Password is required.', pattern: passwordNotMatchMessage}"
      >
    </xcc-input>

    <a
      class="text-sm leading-5 underline text-teal-700 cursor-pointer mb-4"
      [attr.data-test]="'button-toggleParent'"
      (click)="iamParentClick()"
      >{{ toogleText }}</a
    >

    <p class="text-xs leading-disclaimer" *ngIf="iAmParent">
      As a parent, you are purchasing a course for your student, whose email you provided in the field above. If you'd
      like, you may enter your own email below to track your student's progress.
    </p>

    <xcc-input
      *ngIf="iAmParent"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="email"
      label="Parent Email Address"
      controlName="teacherEmail"
      [onBlur]="onBlurParentField"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{
        required: 'Email is required.',
        pattern: 'Enter a valid email address.',
        emailIsEqualToParent: 'Teacher email cannot be the same as the student email',
        emailDomain: 'Invalid email domain'
      }"
    >
    </xcc-input>

    <p class="text-xs leading-disclaimer" *ngIf="userExists && iAmParent">
      {{ userExistInfoText }}
    </p>

    <xcc-input
      *ngIf="!userExists && iAmParent"
      parentClass="flex flex-col items-start items-stretch mb-4"
      type="email"
      autocapitalize="off"
      autocomplete="on"
      label="Confirm Parent Email Address"
      controlName="confirmTeacherEmail"
      [onBlur]=""
      [formGroup]="formGroup"
      [infoIcon]="false"
      [infoTooltip]=""
      [errorMessage]="{ required: 'Reenter parent email address.', pattern: 'Email doesn\'t match.', notMatch: 'Email doesn\'t match.' }"
    >
    </xcc-input>

    <xcc-input
      *ngIf="!userExists && iAmParent"
      parentClass="flex flex-col items-start items-stretch"
      isPasswordInput="true"
      type="password"
      autocapitalize="off"
      autocomplete="off"
      label="Parent Password"
      controlName="password"
      [formGroup]="formGroup"
      [infoIcon]="false"
      infoTooltip=""
      [errorMessage]="{required: 'Password is required.', pattern: passwordNotMatchMessage}"
      >
    </xcc-input>

    <p class="text-xs leading-5 mb-0 text-grey-600" [class]="iAmParent ? 'mt-2' : 'mt-4'">
      We hate spam as much as you do.&nbsp;
      <a
      class="text-xs leading-5 underline text-teal-700 cursor-pointer text-link"
        *ngIf="xccConfig | async as xccConfig"
        [attr.data-test]="'button-see-privacy-policy'"
        [href]="xccConfig.pageConfig.privacyPolicyLink"
        target="_blank"
        rel="noopener"
      >
        See our privacy policy.
      </a>
    </p>
  </form>
</div>
