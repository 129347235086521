import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { XccEnvironment } from '@xcc-client/services';
import type { XccConfig } from '@xcc-models';
import { Subject, map, takeUntil } from 'rxjs';
import { PostPurchaseService } from './aarp-postpruchase.service';

@Component({
  selector: 'aarp-postpurchase',
  templateUrl: './aarp-postpurchase.component.html',
})
export class AarpPostPurchaseComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  xccConfig: XccConfig;

  constructor(
    @Inject('xccEnv') private readonly xccEnv: XccEnvironment,
    private readonly postPurchaseService: PostPurchaseService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onConfigurationChanged = (config: XccConfig): void => {
    this.xccConfig = config;
  };

  get title(): string {
    return 'Thank You for Purchasing Our Course!';
  }

  get subText(): string {
    return "So, what's next?";
  }

  get buttonLabel(): string {
    return 'Start Your Course';
  }

  get webAppUrl(): string {
    return this.xccEnv.xgritWebAppUrl;
  }

  get bullets(): string[] {
    return this.postPurchaseService.isNewUser
      ? this.xccConfig.pageConfig.aarpConfig.postPurchaseNewUserBulletPoints
      : this.xccConfig.pageConfig.aarpConfig.postPurchaseOldUserBulletPoints;
  }
}
