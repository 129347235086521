import type { Validatable } from '@aceable/core';
import type { EventEmitter } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import type { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { XccEnvironment } from '@xcc-client/services';
import { ConfirmEmailValidator } from '@xcc-client/shared/validators/confirm-email-validator/confirm-email-validator';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class XccParentAccountPanelService implements Validatable {
  private readonly formGroup_: FormGroup;
  private readonly isValid_: Observable<boolean>;
  private readonly showError_: Observable<boolean>;
  private useParent$ = new Subject<boolean>();
  private useParent_ = false;
  private userExists_ = false;
  emailRegexPattern =
    /^[a-z0-9!#$%&"*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&"*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  private passwordRegEx: string;

  constructor(formBuilder: FormBuilder, @Inject('xccEnv') readonly xccEnv: XccEnvironment) {
    this.passwordRegEx = this.xccEnv.passwordConfig.passwordRegEx;
    this.formGroup_ = this.createFormGroup(formBuilder);
    this.showError_ = this.formGroup_.statusChanges.pipe(map(this.hasError));
    this.isValid_ = this.formGroup_.statusChanges.pipe(map(this.hasValid));
    this.useParent$.subscribe((status) => (this.useParent_ = status));
  }

  get userExists(): boolean {
    return this.userExists_;
  }

  /**
   * Validates the email domain of a given email address.
   * Returns a ValidatorFn function that can be used in Angular reactive forms.
   * If the email domain is invalid, it returns a ValidationErrors object with the 'emailDomain' key.
   * Otherwise, it returns null.
   *
   * @returns A ValidatorFn function that validates the email domain.
   */
  private emailDomainValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value as string;
      if (email) {
        const parts = email.split('@');
        if (parts.length === 2) {
          const domainParts = parts[1].split('.');
          if (domainParts.length > 1) {
            const tld = domainParts[domainParts.length - 1];
            if (tld.length < 2) {
              return { emailDomain: 'Invalid email domain' };
            }
          }
        }
      }
      return null;
    };
  }

  clearPassword(): void {
    if (this.formGroup_.get('password')) {
      this.formGroup_.get('password').setValue('');
      this.formGroup_.get('password').markAsUntouched();
      this.formGroup_.get('password').markAsPristine();
    }
  }

  /**
   * This will mark the entire form group as touched. it will then force the
   * emission of its status through the statusChanges observable.
   */
  validate = (): void => {
    this.formGroup_.markAllAsTouched();
    const emitValue = this.formGroup_.valid ? 'VALID' : 'INVALID';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.formGroup_.statusChanges as EventEmitter<any>).emit(emitValue);
  };

  goToError(): void {
    const el = document.getElementById('xcc-parent-account-form').querySelector('.ng-invalid');
    if (el) el.scrollIntoView({ behavior: 'smooth' });
  }

  get useParent(): Observable<boolean> {
    return this.useParent$.asObservable();
  }

  get controls(): ReadonlyArray<AbstractControl> {
    return this.getControls();
  }

  get formGroup(): FormGroup {
    return this.formGroup_;
  }

  get showError(): Observable<boolean> {
    return this.showError_;
  }

  get isValid(): Observable<boolean> {
    return this.isValid_;
  }

  updateForm(status: boolean): void {
    if (!status) {
      this.formGroup_.removeControl('teacherEmail');
      this.formGroup_.removeControl('confirmTeacherEmail');
    } else {
      this.formGroup_.addControl(
        'teacherEmail',
        new FormControl('', {
          validators: [Validators.required, Validators.pattern(this.emailRegexPattern), this.emailDomainValidator()],
          updateOn: 'blur',
        }),
      );
      this.formGroup_.addControl(
        'confirmTeacherEmail',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.emailRegexPattern),
          ConfirmEmailValidator('teacherEmail'),
        ]),
      );
    }
    this.formGroup_.updateValueAndValidity();
  }

  updateFormForUserExist(userExists: boolean, isParent: boolean) {
    this.userExists_ = userExists;
    if (userExists) {
      this.formGroup_.removeControl('password');
      if (isParent) {
        this.formGroup_.removeControl('confirmTeacherEmail');
        this.formGroup_.addControl(
          'confirmEmail',
          new FormControl('', [
            Validators.required,
            Validators.email,
            Validators.pattern(this.emailRegexPattern),
            ConfirmEmailValidator('email'),
          ]),
        );
      } else {
        this.formGroup_.removeControl('confirmEmail');
      }
    } else {
      this.formGroup_.addControl(
        'password',
        new FormControl('', [Validators.required, Validators.pattern(this.passwordRegEx)]),
      );
      this.formGroup_.addControl(
        'confirmEmail',
        new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegexPattern),
          ConfirmEmailValidator('email'),
        ]),
      );
      if (isParent) {
        this.formGroup_.addControl(
          'confirmTeacherEmail',
          new FormControl('', [
            Validators.required,
            Validators.email,
            Validators.pattern(this.emailRegexPattern),
            ConfirmEmailValidator('email'),
          ]),
        );
      }
    }
  }

  setUseParent(status: boolean): void {
    this.useParent$.next(status);
    this.updateForm(status);
  }

  enableForm(): void {
    this.formGroup_.enable();
  }

  disableForm(): void {
    this.formGroup_.disable();
  }

  private hasError = (): boolean => {
    for (const control of this.controls) {
      const isBad = control.touched && control.invalid;
      if (isBad) {
        return true;
      }
    }
    return false;
  };

  private hasValid = (status: string): boolean => {
    return status !== 'INVALID';
  };

  private getControls(): ReadonlyArray<AbstractControl> {
    const studentName = this.formGroup_.get('studentName');
    const email = this.formGroup_.get('email');

    const controls = [studentName, email];
    if (this.useParent_) {
      const teacherEmail = this.formGroup_.get('teacherEmail');
      controls.push(teacherEmail);
      const confirmTeacherEmail = this.formGroup_.get('confirmTeacherEmail');
      controls.push(confirmTeacherEmail);
    }

    if (this.userExists_) {
      const confirmEmail = this.formGroup_.get('confirmEmail');
      const password = this.formGroup_.get('password');
      controls.push(confirmEmail, password);
    }

    return controls;
  }

  private createFormGroup(formBuilder: FormBuilder): FormGroup {
    const nameControlConfig = ['', [Validators.required, Validators.pattern(/^[ \t]*\p{L}+(?:[ '-]\p{L}+)*[ \t]*$/u)]];
    const parentEmailControlConfig = [
      '',
      [Validators.required, Validators.email, Validators.pattern(this.emailRegexPattern)],
    ];
    const passwordControlConfig = ['', [Validators.required, Validators.pattern(this.passwordRegEx)]];
    const confirmEmailValidators = [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailRegexPattern),
      ConfirmEmailValidator('email'),
    ];
    const emailControlConfig = [
      '',
      {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegexPattern),
          this.emailDomainValidator(),
        ],
        updateOn: 'blur',
      },
    ];
    const confirmEmailControlConfig = ['', confirmEmailValidators];
    const confirmTeacherEmailValidators = [
      Validators.required,
      Validators.email,
      Validators.pattern(this.emailRegexPattern),
      ConfirmEmailValidator('teacherEmail'),
    ];
    const confirmTeacherEmailControlConfig = ['', confirmTeacherEmailValidators];
    if (!this.useParent_) {
      if (!this.userExists_) {
        return formBuilder.group({
          studentName: nameControlConfig,
          email: emailControlConfig,
          confirmEmail: confirmEmailControlConfig,
          password: passwordControlConfig,
        });
      } else {
        return formBuilder.group({
          studentName: nameControlConfig,
          email: emailControlConfig,
        });
      }
    } else {
      if (!this.userExists_) {
        return formBuilder.group({
          studentName: nameControlConfig,
          teacherEmail: parentEmailControlConfig,
          email: emailControlConfig,
          password: passwordControlConfig, // This is for teacher
          confirmEmail: emailControlConfig,
          confirmTeacherEmail: confirmTeacherEmailControlConfig,
        });
      } else {
        return formBuilder.group({
          studentName: nameControlConfig,
          teacherEmail: parentEmailControlConfig,
          email: emailControlConfig,
          // password: passwordControlConfig, // This is for teacher
          confirmTeacherEmail: confirmTeacherEmailControlConfig,
        });
      }
    }
  }

  /**
   * Checks if the email in the form group is not duplicated.
   * If the teacher's email is the same as the student's email, it sets the 'emailNotEqual' error on the teacherEmail form control.
   * @param formGroup - The form group containing the teacherEmail and email form controls.
   */
  public emailIsNotDuplicated(formGroup: FormGroup): void {
    const teacherEmail = formGroup.get('teacherEmail');
    const studentEmail = formGroup.get('email');
    let errors = teacherEmail.errors || {};

    if (teacherEmail?.value === studentEmail?.value) {
      errors.emailIsEqualToParent = true;
      teacherEmail.setErrors(errors);
    } else {
      errors = teacherEmail.errors;
      teacherEmail.setErrors(errors);
    }
  }
}
