import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type { ActivatedRoute, Params, Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { GeolocationService, XccEnvironment } from '@xcc-client/services';
import type { ErrorList } from '@xcc-models';
import { filter } from 'rxjs/operators';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import type { AdobeAnalyticsData } from './adobe-analytics';
import { AppStep, DeviceType, PurchaseEvents } from './adobe-analytics';

@Injectable({
  providedIn: 'root',
})
export class AdobeAnalyticService {
  private window: Window;
  private adobeSnippet: string;
  private nodeSnippetId = 'adobe-snippet-analytics';
  private nodeDataLayerId = 'adobe-data-layer-analytics';
  private nodeDataLayerPostPurchaseId = 'adobe-snippet-analytics-post-purchase-id';
  private nodeDataErrorLayerId = 'adobe-data-analytics-error-layer';
  private errorCounter = 0;
  private static readonly MOBILE_DEVICE_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  private _deviceType: DeviceType | null = null;
  private defaultDataLayer: AdobeAnalyticsData;
  private adobeConfigPresent = false;

  constructor(
    @Inject('xccEnv') private readonly xccEnv: XccEnvironment,
    @Inject(DOCUMENT) public document: Document,
    private scriptLoaderService: ScriptLoaderService,
    private geolocationService: GeolocationService,
  ) {
    this.window = this.document.defaultView;
    if (this.xccEnv?.adobeAnalytics) {
      this.adobeSnippet = `//assets.adobedtm.com/launch-${this.xccEnv.adobeAnalytics}.min.js`;
    }
  }

  get deviceType(): string {
    if (!this._deviceType) {
      const userAgent = navigator.userAgent;
      if (AdobeAnalyticService.MOBILE_DEVICE_REGEX.test(userAgent)) {
        this._deviceType = DeviceType.MOBILE;
      } else {
        this._deviceType = DeviceType.DESKTOP;
      }
    }
    return this._deviceType;
  }

  /**
   * conditionallySetAdobe call initializeAnalytics if adobe config is present
   *
   * @param adobe
   * @param route
   * @param router
   */
  conditionallySetAdobe(adobe: AdobeAnalyticsData, router: Router, route: ActivatedRoute): void {
    if (adobe) {
      this.adobeConfigPresent = true;
      this.initializeAnalytics(adobe);
      this.listenForPathChanges(router, route);
    }
  }

  /**
   * initializeAnalytics initializes adobe analytics
   *
   * @param adobe
   */
  private initializeAnalytics(adobe: AdobeAnalyticsData) {
    // At this point Adobe snippet was injected
    this.scriptLoaderService.injectScript(this.adobeSnippet, {
      id: this.nodeSnippetId,
      async: true,
    });
    // Get the current state from the geolocation service and then create the data layer
    this.geolocationService.state.subscribe((state) => {
      this.createDataLayer(adobe, state);
    });
  }

  /**
   * createDataLayer creates an adobe analytics object
   *
   * @param {AdobeAnalyticsData} adobeData - Adobe Analytic data from craft entry
   */
  private createDataLayer(adobeData: AdobeAnalyticsData, state?: string) {
    this.defaultDataLayer = adobeData;
    const adobeDataLayerString = `
      if (typeof AARP == "undefined" || AARP === null) {
        AARP = {};
      };
      AARP.MetaInfo = {
        siteProperty: '${adobeData.siteProperty}',
        language: '${adobeData.language}',
        publishdate: '${adobeData.publishDate}',
        publishser: '${adobeData.publishDate}',
        dcSubject1: '${adobeData.dcSubject1}',
        dcSubject2: '${adobeData.dcSubject2}',
        dcSubject3: '${adobeData.dcSubject3}',
        pagedata: {
          pagename: '${adobeData.pageData.pageName ?? ''}',
          var1: '${adobeData.pageData.var1 ?? 'driver safety'}',
          var2: '${adobeData.pageData.var2 ?? 'main'}',
          var3: '${adobeData.pageData.var3 ?? PurchaseEvents.COURSE_PURCHASE ?? ''}',
          var4: '${adobeData.pageData.var4 ?? ''}',
          var5: '${adobeData.pageData.var5 ?? this.window.location.pathname}',
          pagetype: '${adobeData.pageData.pageType ?? ''}',
          publisher: '${adobeData.pageData.publisher ?? ''}',
          channel: '${adobeData.pageData.channel ?? ''}',
          eventtype: '${adobeData.pageData.eventType ?? ''}',
          language: '${adobeData.pageData.language ?? this.window.navigator.language}',
          publishdate: '${adobeData.pageData.publishDate ?? ''}',
          vendortype: '${adobeData.pageData.vendorType ?? ''}',
          metatype: '${adobeData.pageData.metaType ?? ''}',
        },
        userdata: {
          memprogram: '${adobeData.userData.memProgram ?? ''}',
          appstep: '${adobeData.userData.appStep ?? AppStep.LAUNCH ?? ''}',
          zipcode: '${adobeData.userData.zipCode ?? ''}',
          productid: '${adobeData.userData.productId ?? ''}',
          productcode: '${adobeData.userData.productCode ?? ''}',
          accounttype: '${adobeData.userData.accountType ?? ''}',
          devicetype: '${this.deviceType ?? adobeData.userData.deviceType ?? ''}',
          selectedstate: '${state ?? ''}',
        },
        errors: {
          errorcode: '${adobeData.errors.errorCode ?? ''}',
          errormessage: '${adobeData.errors.errorMessage ?? ''}',
        },
        webdata: {
          transactionid: '${adobeData.webData.transactionId ?? ''}',
          registrationtype: '${adobeData.webData.registrationType ?? ''}',
          pymttype: '${adobeData.webData.paymentType ?? ''}',
          programtype: '${adobeData.webData.programType ?? ''}',
          orderamount: '${adobeData.webData.orderAmount ?? ''}',
        },
      };
    `;
    this.scriptLoaderService.injectInlineScript(adobeDataLayerString, {
      id: this.nodeDataLayerId,
      async: false,
      before: this.adobeSnippet,
    });
  }

  /**
   * The postPurchase method is a private method that handles post-purchase actions.
   * It injects the Adobe Data Layer script and triggers a purchase event.
   *
   * @private
   * @method postPurchase
   * @returns {void}
   */
  private postPurchase() {
    const adobeDataLayerString = `
      s.eVar157 = '${PurchaseEvents.PURCHASE_SUCCESSFUL}';
      s.eVar158 = '';
      s.eVar159 = '${this.window.location.pathname}';
      s.eVar168 = '${AppStep.POST_PURCHASE}';
      s.t();
    `;
    this.scriptLoaderService.injectInlineScript(adobeDataLayerString, {
      id: this.nodeDataLayerPostPurchaseId,
      async: false,
      after: this.adobeSnippet,
    });
  }

  /**
   * Listens for path changes.
   *
   * @param {Router} router - The router object.
   * @param {ActivatedRoute} route - The activated route object.
   *
   * @return {void}
   */
  private listenForPathChanges(router: Router, route: ActivatedRoute): void {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      route.queryParams.subscribe((queryParams) => {
        this.onRouteChange(queryParams);
      });
    });
  }

  /**
   * Performs actions when the route changes based on the provided query parameters.
   *
   * @param {Params} queryParams - The query parameters for the route.
   * @return {void}
   */
  onRouteChange(queryParams: Params) {
    if (queryParams['purchaseSuccess'] === 'true') {
      this.scriptLoaderService.removeScript(this.nodeDataLayerId);
      this.postPurchase();
    }
  }

  /**
   * Log errors to Adobe Data Layer.
   * @param {ErrorList[]} errorList - List of errors to log.
   * @returns {void}
   */
  logError(errorList: ErrorList[]) {
    if (this.adobeConfigPresent) {
      for (const error of errorList) {
        const adobeDataErrorLayerString = `
          s.pageName = '${this.defaultDataLayer.pageData.pageName}';
          s.events = 'event51';
          s.pev2 = '${error.message}';
          s.pageUrl = '${this.window.location.pathname}';
          s.tl();
        `;
        this.scriptLoaderService.injectInlineScript(adobeDataErrorLayerString, {
          id: `${this.nodeDataErrorLayerId}-${this.errorCounter}`,
          async: false,
          after: this.adobeSnippet,
        });
        this.errorCounter = this.errorCounter + 1;
      }
    }
  }
}
