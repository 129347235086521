/**
 * Depracted types no longer supported:
 * - RECORD
 * - STUDY_TESTS
 */
export enum ProductTypes {
  BUNDLE = 'BUNDLE',
  CERTIFICATE = 'CERTIFICATE',
  COURSE = 'COURSE',
  EXAM = 'EXAM',
  FEATURE = 'FEATURE',
  FEE = 'FEE',
  SHIPPING = 'SHIPPING',
  UPSELL = 'UPSELL',
  DD = 'DD',
  DE = 'DE',
  MDS = 'MDS',
}
