import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { IterableSubscribeService, XccEnvironment, XgritApiService } from '@xcc-client/services';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import { Brand, CompanyName, SuccessResponse, XccConfig, XgritQueryParams } from '@xcc-models';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { XccParentAccountPanelService } from './xcc-parent-account-panel.service';

@Component({
  selector: 'xcc-parent-account-panel',
  templateUrl: './xcc-parent-account-panel.component.html',
})
export class XccParentAccountPanelComponent {
  parentText = "Wait, I'm a parent!";
  studentText = "Nevermind, I'm a student";
  toogleText = this.parentText;
  iAmParent = false;
  readonly xccConfig: Observable<XccConfig>;
  private readonly ngUnsubscribe = new Subject<void>();
  private xccConfig_: XccConfig;
  public emailInfoMessage: string;
  private lastStudenEmail: string;
  private lastParentEmail: string;
  userExistInfoText = 'Your course will be added to your existing account.';

  constructor(
    readonly parentAccountPanelService: XccParentAccountPanelService,
    private readonly route: ActivatedRoute,
    private readonly iterableService: IterableSubscribeService,
    private readonly paymentContinueService: PaymentContinueService,
    private readonly xgritApiService: XgritApiService,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {
    this.xccConfig = this.route.data.pipe(map((data: Data) => data.xccConfig));
    this.parentAccountPanelService.useParent.subscribe((status) => (this.iAmParent = status));
    this.paymentContinueService.setUseParent(true);

    this.emailInfoMessage = `Your confidentiality is important to us so you will only receive information directly from ${this.setBrandName(
      this.xccEnv.brand.toUpperCase(),
    )}. Don't find the information helpful? No worries, you can unsubscribe at anytime.`;
  }

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((data: Data) => data.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

      this.route.queryParams.subscribe((queryParams: XgritQueryParams) => {
        if(queryParams.parent === 'true'){
          this.iamParentClick();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.formGroup.reset();
  }

  /**
   * Handles the onBlur event for the parent field.
   * If the teacherEmail field is valid, subscribes the user to the Iterable service.
   * @returns {void}
   */
  onBlurParentField = (): void => {
    const teacherEmail = this.formGroup.get('teacherEmail');
    /**
     * Check if the student email and parent email is not
     * duplicated, if the parent email is duplicated, set
     * the teacherEmail field to invalid and show error message.
     */
    this.parentAccountPanelService.emailIsNotDuplicated(this.formGroup);

    if (teacherEmail.valid) {
      const fields: ParentPurchasePayload = {
        name: '', // TODO: Validate what are we going to use for name value
        email: teacherEmail.value,
        courseState: this.xccConfig_.productConfig.state,
      };
      this.iterableService.subscribeUser(fields, this.xccConfig_.productConfig.segment, true).pipe(take(1)).subscribe();
      if(this.lastParentEmail !== teacherEmail.value && this.iAmParent){
        this.checkIfUsersExists(teacherEmail.value);
        this.lastParentEmail = teacherEmail.value;
      }
    }
  };

  /**
   * Handles the onBlur event for the student field.
   * If the studentEmail field is valid, subscribes the user to the Iterable service.
   * @returns {void}
   */
  onBlurStudentField = () => {
    const name = this.formGroup.get('studentName').value ? this.formGroup.get('studentName').value.trim() : '';
    const emailField = this.formGroup.get('email');
    if (emailField.valid) {
      const fields: ParentPurchasePayload = {
        name,
        email: emailField.value,
        courseState: this.xccConfig_.productConfig.state,
      };

      this.iterableService.subscribeUser(fields, this.xccConfig_.productConfig.segment).pipe(take(1)).subscribe();
    }
  };

  onBlurStudentEmail = () => {
    const name = this.formGroup.get('studentName').value ? this.formGroup.get('studentName').value.trim() : '';
    const emailField = this.formGroup.get('email');
    if (emailField.valid) {
      const fields: ParentPurchasePayload = {
        name,
        email: emailField.value,
        courseState: this.xccConfig_.productConfig.state,
      };

      this.iterableService.subscribeUser(fields, this.xccConfig_.productConfig.segment).pipe(take(1)).subscribe();
      if(this.lastStudenEmail !== emailField.value && !this.iAmParent){
        this.checkIfUsersExists(emailField.value);
        this.lastStudenEmail = emailField.value;
      }
    }
  }

  checkIfUsersExists = (username: string) => {
    const params = { username, brandId: this.xccEnv.brand.toUpperCase() };
    this.xgritApiService.verifyEmail(params).subscribe((response: SuccessResponse) => { 
      this.parentAccountPanelService.updateFormForUserExist(response?.data?.verifyEmail, this.iAmParent);
    });
  }

  get userExists(): boolean {
    return this.parentAccountPanelService.userExists;
  }

  get formGroup(): FormGroup {
    return this.parentAccountPanelService.formGroup;
  }

  get showError(): Observable<boolean> {
    return this.parentAccountPanelService.showError;
  }

  get passwordNotMatchMessage(): string {
    return this.xccEnv.passwordConfig.passwordNotMatchMessage;
  }

  private onConfigurationChanged = (xccConfig: XccConfig): void => {
    this.xccConfig_ = xccConfig;
  };

  iamParentClick() {
    this.parentAccountPanelService.clearPassword()
    this.iAmParent = !this.iAmParent;
    this.toogleText = this.iAmParent ? this.studentText : this.parentText;
    this.parentAccountPanelService.setUseParent(this.iAmParent);
    this.parentAccountPanelService.updateFormForUserExist(false, this.iAmParent);
    
    this.onBlurStudentField();
  }

  private setBrandName = (brand: string): CompanyName => {
    const brandMap = {
      [Brand.IDS]: CompanyName.IDS,
      [Brand.DEC]: CompanyName.DEC,
      [Brand.AA]: CompanyName.AA,
      [Brand.ACE]: CompanyName.ACE,
    };
    return brandMap[brand] || CompanyName.ACE;
  };
}

interface ParentPurchasePayload {
  name: string;
  email: string;
  courseState: string;
  teacherEmail?: string;
}
