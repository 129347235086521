import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  AddOn,
  Product,
  ProductTypes,
  PurchaseReceiptPriceParams,
  UidList,
  XccConfig,
  XgritPricingResponse,
  XgritPricingSuccessful,
} from '@xcc-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { RsaClaimStatusService, XgritApiService, ActiveLicenseService } from '@xcc-client/services';
import { map } from 'rxjs/operators';
import { CouponService } from '@xcc-client/shared/components/shopping-cart/coupon/coupon.service';

@Injectable()
export class UpsellAddOnsService {
  private originalMainProduct: Product;
  private originalCdiDiscount: Product;
  private hasCdiFlag = false;
  // IDS FL.DE
  private hasBundleCoupon = false;
  private bundleProductWithDiscount = new BehaviorSubject<AddOn>(undefined);
  private rsaClaimed = false;
  private isBundleSelected$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly cartService: ShoppingCartService,
    private readonly route: ActivatedRoute,
    private readonly xgritApiService: XgritApiService,
    private readonly rsaStatusService: RsaClaimStatusService,
    private readonly activeLicenseService: ActiveLicenseService,
    private readonly couponService: CouponService,
  ) {
    this.hasCdiFlag =
      this.route.snapshot.queryParamMap.get('cdiFlag') === '0' ||
      this.route.snapshot.queryParamMap.get('threeStep') === 'false';
    this.hasBundleCoupon = this.route.snapshot.queryParamMap.get('bundleCoupon') !== null;

    this.rsaStatusService.rsaClaimStatus.subscribe((claimed) => (this.rsaClaimed = claimed));
  }

  get isBundleSelected(): Observable<boolean> {
    return this.isBundleSelected$.asObservable();
  }

  setIsBundleSelected(status: boolean) {
    this.isBundleSelected$.next(status);
  }

  fetchBundleWithCoupon = (xccConfig: XccConfig): void => {
    const defaultProductsIds = xccConfig.productConfig.defaultProducts
      .filter((product) => product.productId)
      .map((product) => product.productId);
    const addOnsIds = xccConfig.productConfig.addOnConfig.addOns
      .filter((product) => product.productId)
      .map((product) => product.productId);
    const bundleCoupon = this.route.snapshot.queryParamMap.get('bundleCoupon');

    const params: PurchaseReceiptPriceParams = {
      productIdList: [...defaultProductsIds, ...addOnsIds],
      couponCodeList: [bundleCoupon],
    };

    this.xgritApiService
      .getPurchaseReceiptPrice(params, true)
      .pipe(map((pricingResponse) => this.setBundleProductWithCoupon(pricingResponse)))
      .subscribe((data) => data);
  };

  setBundleProductWithCoupon(pricingResponse: XgritPricingResponse) {
    const bundleProduct = (pricingResponse as XgritPricingSuccessful).lineItemList.find(
      (item) => item.product.type === ProductTypes.BUNDLE,
    );

    const bundleAddOn: AddOn = {
      customerPrice: bundleProduct.chargeAmount,
      preCheck: false,
      productId: bundleProduct.product._id,
      strikePrice: bundleProduct.product.pricing.current,
      title: bundleProduct.title,
      type: 'addOn',
      subtitle: bundleProduct.description,
      uid: UidList.courseBundle,
    };

    this.bundleProductWithDiscount.next(bundleAddOn);
  }

  handleSelectionChange(addOn: AddOn, isSelected: boolean, toggleableAddOn?: Product) {
    const { customerPrice, title: label, replaceCartMainProduct, togglesAddOns, type, uid, videoCourseId } = addOn;
    const newProduct: Product = {
      customerPrice,
      label,
      replaceCartMainProduct,
      togglesAddOns,
      type,
      uid,
      videoCourseId,
      productId: addOn.productId,
    };

    /**
     * If UID course-bundle product is added to the cart and a bundleCoupon query param is
     * present, we must use its strikePrice as the price in the cart (instead of customerPrice).
     * This way if the user accepts CDI, the shopping cart will contain a line item with a
     * discount. So it would end up being strikePrice - rsaDiscount = customerPrice.
     */
    const addOnHasDiscount = addOn.customerPrice !== addOn.strikePrice && addOn.strikePrice !== 0;
    const hasUidCourseBundle = addOn.uid === UidList.courseBundle;

    if (addOnHasDiscount && hasUidCourseBundle) {
      newProduct.customerPrice = addOn.strikePrice;
    }

    if (addOn.replaceCartMainProduct === true) {
      // Logic for IDS.FL.DE with bundleCoupon to replace the Special Discount
      this.replaceSpecialDiscount(addOn, isSelected);
      this.handleEmptyBundleCoupon(isSelected);
      this.replaceCartMainProduct(newProduct, isSelected);
      return;
    }

    if (addOn.data?.['license']) {
      this.activeLicenseService.updateActiveLicense(isSelected ? addOn.data['license'] : '');
    }

    if (addOn.replacementToggleUid !== undefined) {
      this.replacementToggleUid(addOn, newProduct, isSelected, toggleableAddOn);
      return;
    }

    if (isSelected) {
      this.cartService.addProduct(newProduct, 1);
    } else {
      const existingProduct = this.cartService.getProductByLabel(label);
      this.cartService.removeProduct(existingProduct, 1);
    }
  }

  handleEmptyBundleCoupon(isSelected: boolean): void {
    /**
     * When having no bundleCoupon but a discount or cdiFlag:
     * - When adding the bundle the Special Discount should be removed
     * - When removing the bundle the Special Discount should be restored
     */
    if (!this.hasBundleCoupon) {
      let existingDiscount: Product;
      try {
        existingDiscount = this.cartService.getProductByLabel(this.originalCdiDiscount.label);
      } catch (error) {}

      if (isSelected && existingDiscount) {
        this.cartService.removeProduct(existingDiscount);
      } else if ((!isSelected && this.rsaClaimed) || this.hasCdiFlag) {
        this.cartService.addProduct(this.originalCdiDiscount);
      }
    }
  }

  // Logic for IDS.FL.DE with bundleCoupon to replace the Special Discount
  replaceSpecialDiscount(addOn: AddOn, isSelected: boolean): void {
    if (this.hasBundleCoupon && this.hasCdiFlag) {
      const bundleDiscount = parseFloat(addOn.strikePrice?.toString()) - parseFloat(addOn.customerPrice?.toString());
      let existingDiscount: Product;
      let newDiscount: Product;
      /**
       * getProductByLabel method throws an error if the product is not found,
       * when having only bundleCoupon and the cdiFlag, there's no original 'Special Discount' product,
       * therefore we have to create the discount Product when selecting the 'course-bundle' addOn
       */
      try {
        existingDiscount = this.cartService.getProductByLabel('Special Discount');
        newDiscount = { ...existingDiscount };
        this.cartService.removeProduct(existingDiscount);
      } catch (error) {
        newDiscount = {
          uid: UidList.couponDiscount,
          label: 'Special Discount',
          customerPrice: bundleDiscount,
        };
      }

      if (isSelected) {
        newDiscount.customerPrice = -bundleDiscount;
      } else {
        newDiscount.customerPrice = this.originalCdiDiscount.customerPrice;
      }

      this.cartService.addProduct(newDiscount);
    }
  }

  get toggleableProduct(): Observable<Product> {
    return this.cartService.toggleableProduct;
  }

  get bundleWithDiscountAddOn(): Observable<AddOn> {
    return this.bundleProductWithDiscount.asObservable();
  }

  /**
   * This backs up the discount Product for the cases it is removed from cart and needs
   * to come back (bundles, permits, ...).
   */
  set originalDiscountProduct(discount: Product) {
    this.originalCdiDiscount = discount;
  }

  private replacementToggleUid(addOn: AddOn, newProduct: Product, isSelected: boolean, toggleableAddOn: Product): void {
    newProduct.replacementToggleUid = addOn.replacementToggleUid;
    let productToRemove: Product;
    let productToAdd: Product;

    if (isSelected) {
      productToRemove = this.cartService.getProductByLabel(toggleableAddOn.label);
      productToAdd = newProduct;
    } else {
      productToRemove = this.cartService.getProductByLabel(newProduct.label);
      productToAdd = toggleableAddOn;
    }

    this.cartService.removeProduct(productToRemove, 1);
    this.cartService.addProduct(productToAdd, 1);
  }

  private replaceCartMainProduct(newProduct: Product, isSelected: boolean): void {
    if (!this.originalMainProduct) {
      this.originalMainProduct = this.cartService.getMainProduct();
    }

    let productToRemove: Product;
    let productToAdd: Product;

    if (isSelected) {
      this.setIsBundleSelected(true);
      // If coupon is added
      if (this.couponService.couponCodeValue) {
        this.couponService.removeCouponFromCart();
      }
      productToRemove = this.originalMainProduct;
      productToAdd = newProduct;
    } else {
      this.setIsBundleSelected(false);
      productToRemove = this.cartService.getProductByLabel(newProduct.label);
      productToAdd = this.originalMainProduct;
    }

    this.cartService.removeProduct(productToRemove, 1);
    this.cartService.addProduct(productToAdd);
  }
}
