import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { RsaOfferService, WizardStepsService, XccImgixService } from '@xcc-client/services';
import { rsaOfferCartProduct } from '@xcc-models';

/**
 * IMPORTANT NOTE:
 * This component does not support conditional discounts. By default the coupon should always be applied despite
 * whether the user accepts or declines the RSA offer.
 */

@Component({
  selector: 'xcc-rsa-offer-panel',
  templateUrl: './rsa-offer-panel.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsaOfferPanelComponent implements OnInit {
  private ngUnsubscribe = new Subject<void>();
  private rsaOfferCartProduct = rsaOfferCartProduct;
  private rsaOfferCode: string;
  private wizardIndex = 0;
  constructor(
    readonly wizardStepsService: WizardStepsService,
    private readonly xgritRsaService: RsaOfferService,
    private readonly xccImgixService: XccImgixService,
    private readonly route: ActivatedRoute,
  ) {
    this.route.queryParamMap.subscribe((params) => (this.rsaOfferCode = params.get('coupon')));
  }

  get rsaImage(): string {
    return this.xccImgixService.buildImgixUrl('allstate-roadside.jpg', { width: 407, height: 451 });
  }

  ngOnInit(): void {
    this.wizardStepsService.indexChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((wizardIdx) => {
      this.wizardIndex = wizardIdx;
    });
    // Setting to false because this component will not support conditional discounts
    this.xgritRsaService.updateHasDiscount(false);

    // Enable RSA conditional discount panel
    this.xgritRsaService.init();
  }

  onClaimButtonClicked(): void {
    this.rsaOfferCartProduct.couponCode = this.rsaOfferCode;
    // Setting to true to add "1 Month Allstate Roadside  FREE" as a shopping cart line item when RSA is claimed
    this.xgritRsaService.onClaimed(this.rsaOfferCartProduct, true);
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  }

  onDeclineButtonClicked(): void {
    const newIdx = this.wizardIndex + 1;
    this.wizardStepsService.updateStep(newIdx);
    this.wizardStepsService.scrollToTop();
  }
}
