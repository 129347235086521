import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { AddOn, rsaOfferCartProduct } from '@xcc-models';
import { RsaClaimStatusService, RsaOfferService } from '@xcc-client/services';
import { AddOnEmitted } from '../shared/emmiter';

@Component({
  selector: 'xcc-rsa-checkbox-offer',
  templateUrl: './rsa-checkbox-offer.component.html',
  styleUrls: ['../shared/upsell-panel.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RsaCheckboxOfferComponent implements OnInit, OnDestroy {
  @Output() addOnSelected = new EventEmitter<AddOnEmitted>();
  @Input() addon: AddOn;
  @Input() displayStrikethrough: boolean;
  @Input() set isSelected(value: boolean) {
    this._isSelected = !!value;

    window.setTimeout(() => {
      if (this._isSelected) {
        this.claimOffer();
      } else {
        this.unclaimOffer();
      }
    }, 0);
  }

  faCheckCircle = faCheckCircle;
  cartProduct = rsaOfferCartProduct; // Product to be added to the cart when selected
  rsaClaimStatus: boolean;

  private _isSelected: boolean;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly xgritRsaService: RsaOfferService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
  ) {}

  ngOnInit(): void {
    this.rsaClaimStatusService.rsaClaimStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
      this.rsaClaimStatus = status;
      this._isSelected = this.rsaClaimStatus;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  claimOffer(): void {
    this.xgritRsaService.onClaimed(this.cartProduct, true);
  }

  unclaimOffer(): void {
    this.xgritRsaService.onUnclaimed(this.cartProduct, true);
  }

  onToggleOption() {
    const newSelectState = !this._isSelected;
    this.addOnSelected.emit({ addOn: this.addon, isSelected: newSelectState });
  }
}
