import type { CheckoutLink } from '@xcc-models';

export const COURSES: CheckoutLink[] = [
  {
    queryParams: { courseId: 'P5uZ1EfCRZMZMx18', productId: 'GkonTogBIpEf3eSn' },
    title: 'AARP New York 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: '6LoNvCfMWm06dtom', productId: 'kka7X1HYbHvNQz1o' },
    title: 'AARP Vermont 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'Q4gg1wxDtPVmiskF', productId: 'RE3JVUBRcyj58kvZ' },
    title: 'AARP Virginia 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'xDf6cDyxCe3b0NsQ', productId: '2B9Sa0zyFGvm3SRN' },
    title: 'AARP Virgin Islands 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'svbmfxLmENWQFBCL', productId: 'F8GFuBT7hDmrVsoq' },
    title: 'AARP Washington 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'fWJZxuRjJTOys8pW', productId: 'GcYGcwAMLPYlHfZR' },
    title: 'AARP West Virginia 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'NL876SP35fNHWptX', productId: 'vgIIe36H9LJaBhcv' },
    title: 'AARP West Virginia 4-Hour Smart Driver Course ( refresher course )',
    inProd: false,
  },
  {
    queryParams: { courseId: 'JsB25V0ChnkHKk5f', productId: '7LBhBjBXO2Qc0FmI' },
    title: 'AARP Wisconsin 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'pTvUYFH3VETyLUhk', productId: '3jnJxZuGvhbGqHZv' },
    title: 'AARP Wyoming 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: '992NNMrdDQ0E2DOX', productId: 'Vpwt5PGgITaVf6EN' },
    title: 'AARP Guam 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'MlIxuGUBeRM6HWqR', productId: 'COufZ6KxWXwAT3dZ' },
    title: 'AARP Utah 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'mZz2LEO7j9jdc3JO', productId: 'kmEuf3i5RlyDSMth' },
    title: 'AARP Texas 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'rJoyEmdcKHX5qGZJ', productId: 'CqvwNMoj9NacaPpE' },
    title: 'AARP Tennessee 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'mxgYoGpAnZR62awJ', productId: '4ZMOLDU9WwfcpidB' },
    title: 'AARP South Dakota 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'qLeB0b6Ml4W6TeAA', productId: 'kSEeHqLjRrFrCJ5H' },
    title: 'AARP South Carolina 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'xWv6vyKgIGrdMHLU', productId: 'mR2x3RGpBKY38O6U' },
    title: 'AARP Rhode Island 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'JgyQjHBx3QTRqz4g', productId: 'hEkZZdGlrEn73QIw' },
    title: 'AARP Puerto Rico 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'b379xCB977E89MNW', productId: 'VtE2SR1EFBQBvnmb' },
    title: 'AARP Oregon 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'H0NnagIiyHDulTdn', productId: 'IGPgd07D9sbRFItI' },
    title: 'AARP Oklahoma 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'cJuri7ZODAsutG87', productId: 'CMpbKfXnUWcaflBE' },
    title: 'AARP Ohio 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'X0FLN2CFKITQcwuY', productId: 'Bfhne8PBQOaKvSde' },
    title: 'AARP North Dakota 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'QyFY3DV3qaCsmHPP', productId: 'KvWOdtpFcIZ9Bma9' },
    title: 'AARP North Carolina 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'sAYrxAWz1jZhUT9z', productId: 'n2qKIXrrNlERZBWV' },
    title: 'AARP New Mexico 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'O0JcJmDjhUxTG0Hi', productId: 'KYV5JuP4TMTCYeCH' },
    title: 'AARP New Hampshire 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'HLXFtVwn3pFqBiVn', productId: 'xCVkxmaWaowiQlZR' },
    title: 'AARP Nebraska 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'rpjZm1GjVnGz4w7t', productId: 'vAaNLJiV5adUu9fO' },
    title: 'AARP Nevada 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'AzXfBy1xpyBLDy9F', productId: 'dlwcUGAxzjEQSv3h' },
    title: 'AARP Montana 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'Rdoxh6E6KasBO0tM', productId: 'PcnCM09qE1OSl9ka' },
    title: 'AARP Missouri 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'QaiQ0BYqdIamE2jS', productId: 'NJSswMfnFrGryuNs' },
    title: 'AARP Mississippi 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'm19eF9N20d2sQ1da', productId: 'avywstjs1k2CueTt' },
    title: 'AARP Minnesota 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'B2g0HnzI8kLs1R5b', productId: 'cJYHfVcyN43qpIQx' },
    title: 'AARP Michigan 4-Hour Smart Driver Course (refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: '1WphBDhuXGO1rjIE', productId: 'lGMMuCNLsC9AASYF' },
    title: 'AARP Michigan 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'Z23fTCAYozgIHMcz', productId: '9LdCSgwdJEFAbThm' },
    title: 'AARP Massachusetts 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'eUiCCgCtfTSBaYPQ', productId: 'P2EwaQQtBKl4uKgC' },
    title: 'AARP Maryland 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'mwtGq4IGtJXD7i3v', productId: 'hkC7dND5OLOt2EVn' },
    title: 'AARP Maine 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'VRBNoGrgrqDx0PPL', productId: '5mS2sPXcr8rTeX3m' },
    title: 'AARP Kentucky 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'LFW3BIk7kiNhrAUC', productId: 'QmuL9dDDgJtAV6my' },
    title: 'AARP Kansas 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'dFfDHHE9ZKGse23D', productId: 'O8rqYrBFKSDUBCGb' },
    title: 'AARP Iowa 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'IfqBMpQNd0OpipDa', productId: 'SylKSR7uhjB6CMMD' },
    title: 'AARP Indiana 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'gqYHEbBv8CuwO4vZ', productId: 'pEOkBeFT7CdqGqJO' },
    title: 'AARP Illinois 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'IW5fdHDwWSORZYCA', productId: 'DrNWwJSwsWxPMsQG' },
    title: 'AARP Idaho 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'awAlIw54LMexME61', productId: '6YQjoAWGgQD2PHaV' },
    title: 'AARP Hawaii 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'WuuBGzuv3r46sOob', productId: 'BDObD4ddioTpMe88' },
    title: 'AARP Georgia 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'F1ryAik97iK6mb9N', productId: 'Ocgl0vTcBRQDSHVj' },
    title: 'AARP District Of Columbia 4-Hour Smart Driver C...(refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: '5qBQZGCyn0SBCY1E', productId: 'NSTrIWmhZGgWg5mP' },
    title: 'AARP District Of Columbia 6-Hour Smart Driver C...',
    inProd: false,
  },
  {
    queryParams: { courseId: 'WU36vBJ1m5ptJv6l', productId: 'tfP0IhE0O4jvwVsD' },
    title: 'AARP Colorado 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'L87l6I6Fq1cT8x9m', productId: 'usgc2ryRYWwTLrag' },
    title: 'AARP California 4-Hour Smart Driver Course (refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: 'FzFTHaKDcjFrxCUt', productId: '5mqQjXYHdTWDDYoj' },
    title: 'AARP California 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'PABPXW27uqVwFgBT', productId: 'fo0lLF35YvSEdeQC' },
    title: 'AARP Arkansas 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'Om2YuEsZzfZjwvVz', productId: 'BgofPJZkArm5Cbzk' },
    title: 'AARP Arizona 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'HZuGcIAkfZtwK6sG', productId: 'f72UtnfNQ1AsfK0d' },
    title: 'AARP Alaska 4-Hour Smart Driver Course (refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: 'xZT4CJftCzHh843S', productId: 'XKCDogvgex84t0vL' },
    title: 'AARP Alaska 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'igN1hTGYxxEB2fkz', productId: 'W81twjzQXDW6Fw8r' },
    title: 'AARP Alabama 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'NvaqQ0LSzrdVvYQu', productId: 'azmkD3Cn7HmVOYH1' },
    title: 'AARP Delaware 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 's29Hx6NIuIiIXNJn', productId: 'Eqcf67nHFfqIQqoq' },
    title: 'AARP Delaware 4-Hour Smart Driver Course (refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: '15wCWMaMaFnTt5W6', productId: 'EkE3fAU4DU8Ywza3' },
    title: 'AARP Pennsylvania 4-Hour Smart Driver Course (refresher course)',
    inProd: false,
  },
  {
    queryParams: { courseId: 'F3XEB3SldgffDoR7', productId: 'cDSZBJ6EFVRgqEN8' },
    title: 'AARP Pennsylvania 8-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'Fd4bdwmkziH2yOgp', productId: 'gRTealBmUwqLLxnt' },
    title: 'AARP Connecticut 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: '3F1R9QGWuTmczMxR', productId: 'UVkLCmqruNcxiFVO' },
    title: 'AARP New Jersey 6-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'x3xEEw6ymSExrivn', productId: 'MqVATwKWnwTzOlBT' },
    title: 'AARP Louisiana 4-Hour Smart Driver Course',
    inProd: false,
  },
  {
    queryParams: { courseId: 'SLnzAX5HpGHfS84N', productId: 'PwEWlOwcxDHRRap2' },
    title: 'AARP Florida 6-Hour Smart Driver Course',
    inProd: false,
  },
];
