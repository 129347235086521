import { Injectable } from '@angular/core';

import { Product, ProductTypes } from '@xcc-models';
import { RsaClaimStatusService } from './rsa-claim-status.service';
import { ActivatedRoute } from '@angular/router';
import { ShoppingCartService } from '@xcc-client/shared/components/shopping-cart/shopping-cart.service';

@Injectable({
  providedIn: 'root',
})
export class RsaOfferService {
  hasDiscount = false;

  /**
   * We have two types of coupon:
   * - Default Coupon Discounts: used to add a default discount if user claims RSA (coupon || bundleCoupon)
   * - Additional/Extra Discount: used for marketing purposes or partner programs (couponCode || hc || vc || crohc)
   */

  private conditionalCoupon: string; // Used to store default coupon discount (RSA)
  private conditionalBundleCoupon: string; // Used to store defualt coupon discount dedicated for bundle products

  constructor(
    private readonly cartService: ShoppingCartService,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
    private readonly route: ActivatedRoute,
  ) {
    this.conditionalCoupon = this.route.snapshot.queryParamMap.get('coupon');
    this.conditionalBundleCoupon = this.route.snapshot.queryParamMap.get('bundleCoupon');

    switch (this.cartService.productType) {
      case ProductTypes.DD:
      case ProductTypes.MDS:
        this.cartService.addNonConditionalCoupon(this.conditionalCoupon);
        break;
    }
  }

  init() {
    this.rsaClaimStatusService.updateRsaComponentLoaded(true);
  }

  reset() {
    this.rsaClaimStatusService.resetState();
  }

  onClaimed(product?: Product, shouldAddToCart?: boolean): void {
    this.rsaClaimStatusService.updateRsaClaimStatus(true);

    if (product && (this.hasDiscount || shouldAddToCart)) {
      switch (this.cartService.productType) {
        case ProductTypes.DD:
        case ProductTypes.MDS:
          this.cartService.addProduct(product);
          break;

        default:
          this.cartService.addConditionalCoupon(this.conditionalCoupon);
          break;
      }

      if (this.conditionalBundleCoupon) {
        // Add bundleCoupon if it is present on the url, it is added into the defaultCoupons array from the shopping cart
        this.cartService.addConditionalCoupon(this.conditionalBundleCoupon);
      }
    }
  }

  onUnclaimed(product?: Product, shouldRemoveFromCart?: boolean): void {
    this.rsaClaimStatusService.updateRsaClaimStatus(false);

    if (product && (this.hasDiscount || shouldRemoveFromCart)) {
      this.cartService.cleanDefaultCouponList();
      // Remove RSA product when it is FREE
      this.cartService.removeProduct(product);
    }
  }

  updateHasDiscount(hasDiscount: boolean): void {
    this.hasDiscount = hasDiscount;
    this.rsaClaimStatusService.updateIsDiscountConditional(hasDiscount);
  }
}
