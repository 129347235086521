import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { catchError, map, skip, switchMap, tap } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { CourseIdService } from '@xcc-client/services/lib/courseId-service';
import { SegmentTrackingService } from '@xcc-client/services/lib/segment/segment-tracking.service';
import { XccEnvironment } from '@xcc-client/services/lib/xcc-environment';
import { XgritApiService } from '@xcc-client/services/lib/xgrit-api.service';
import type {
  CouponList,
  LineItemList,
  Product,
  ProductAddedParams,
  PurchaseReceiptPriceParams,
  XgritPricingSuccessful,
} from '@xcc-models';
import { Brand, UidList, XccWindow } from '@xcc-models';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private readonly totalPriceDollarsSubject = new ReplaySubject<number>(1);
  private readonly productsSubject = new ReplaySubject<Map<Product, number>>(1);
  private readonly productsArray: Observable<Product[]>;
  private readonly toggleableProductSubject = new ReplaySubject<Product>(1);
  private toggleableProductSet = false;
  private products = new Map<Product, number>();

  // Used to store the coupon from the UI if it is stored on the price response
  private couponFromResponse$ = new BehaviorSubject<boolean>(false);

  // NonRSA Storage discount
  private conditionalCouponDiscounts: string[] = [];
  public conditionalCouponDiscounts$ = new BehaviorSubject<string[]>([]);

  private nonConditionalCouponDiscounts: string[] = [];
  public nonConditionalCouponDiscounts$ = new BehaviorSubject<string[]>([]);

  private bundleCouponDiscount: string;
  public bundleCouponDiscount$ = new BehaviorSubject<string>(null);

  public additionalCouponDiscount$ = new BehaviorSubject<string[]>([]);
  public additionalCouponDiscount: string[] = [];

  public additionalCouponResponse$ = new BehaviorSubject<CouponList>(null);
  public hasAdditionalCoupon$ = new BehaviorSubject<boolean>(false);

  private uhcDiscountSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public uhcDiscount = this.uhcDiscountSubject.asObservable();

  public productType: string;

  public shoppingCartIsLoading = false;

  private hasReferralCoupon: boolean;
  private courseId: string;
  private segment: string;
  private updatePricesTrigger: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    @Inject('window') private readonly window: XccWindow,
    private readonly segmentTracking: SegmentTrackingService,
    private readonly xgritApiService: XgritApiService,
    private readonly route: ActivatedRoute,
    private readonly courseIdService: CourseIdService,
  ) {
    this.productsArray = this.productsSubject.pipe(map(this.transformMapToArray));
    this.hasReferralCoupon = this.route.snapshot.queryParamMap.get('couponAmbassadorRSA') !== null;
    this.courseIdService.courseId.subscribe((courseId) => {
      this.courseId = courseId;
    });
    this.currentPriceUpdate.subscribe({
      next: () => {
        // This can remain empty because the logic for updating the price is handled by the switchMap.
      },
    });
  }

  public setSegment = (segment: string) => {
    this.segment = segment;
  };

  /**
   * Sets the label for the RSA (Roadside Assistance) discount.
   * @param {boolean} isDD - Whether the discount is a DD (Deductible Dollars) discount.
   * @returns {string} The label for the RSA discount.
   */
  private setRSALabel = (isDD?: boolean): string => {
    const brand = this.xccEnv.brand.toUpperCase();

    if (isDD) {
      switch (brand) {
        case Brand.IDS:
        case Brand.DEC:
          return 'Savings';
        default:
          return 'Discount';
      }
    } else {
      // For ACE Handle the case when isDD is false (provide a default value)
      // If brand is AARP return AARP Member Discount
      return brand === Brand.AARP ? 'AARP Member Discount' : 'Offer - 1 month of Allstate Roadside Service FREE';
    }
  };

  /**
   * Sets the product type for the shopping cart.
   * @param {string} type - The product type to set.
   */
  public setProductType = (type: string) => {
    this.productType = type;
  };

  /**
   * Adds a non-conditional coupon code to the shopping cart.
   * @param {string} code - The coupon code to add.
   */
  public addNonConditionalCoupon = (code: string) => {
    this.nonConditionalCouponDiscounts.push(code);
    this.nonConditionalCouponDiscounts$.next(this.conditionalCouponDiscounts);
  };

  /**
   * Adds a conditional coupon code to the shopping cart.
   * @param {string} code - The coupon code to add.
   */
  public addConditionalCoupon = (code: string) => {
    this.conditionalCouponDiscounts.push(code);
    this.conditionalCouponDiscounts$.next(this.conditionalCouponDiscounts);
  };

  /**
   * Adds a bundle coupon code to the shopping cart.
   * @param {string} code - The coupon code to add.
   */
  public addBundleCoupon = (code: string) => {
    this.bundleCouponDiscount$.next(code);
  };

  /**
   * Adds an additional coupon code to the shopping cart.
   * @param {string} code - The coupon code to add.
   */
  public addAdditionalCoupon = (code: string) => {
    this.additionalCouponDiscount.push(code);
    this.additionalCouponDiscount$.next(this.additionalCouponDiscount);
  };

  /**
   * Observable of total price in dollars
   * @return {number} - an observable of the total price in dollars of products in the shopping cart
   */
  get totalPriceDollarsChanged(): Observable<number> {
    return this.totalPriceDollarsSubject.asObservable();
  }

  /**
   * Observable of the coupon from price response
   * @return {CouponList} - an observable of the coupon if exists from the price response
   */
  get couponFromResponse(): Observable<boolean> {
    return this.couponFromResponse$.asObservable();
  }

  /**
   * Observable of products and quantities
   * @returns {Observable<Map<Product, number>>} - an observable of a map of products and their quantities
   */
  get productsChanged(): Observable<Map<Product, number>> {
    return this.productsSubject.asObservable();
  }

  get productsAsArray(): Observable<Product[]> {
    return this.productsArray;
  }

  get toggleableProduct(): Observable<Product> {
    return this.toggleableProductSubject.asObservable();
  }

  get unitTestProductState(): Product[] {
    // Return map as array
    return Array.from(this.products.keys());
  }

  setUhcDiscount = (value: boolean) => {
    this.uhcDiscountSubject.next(value);
  };

  clear(): void {
    this.products.clear();
    this.broadcastProductCollectionChange();
  }

  refresh(): void {
    this.broadcastProductCollectionChange();
  }

  /**
   * This function filters and maps the product IDs from a list of products.
   * @returns The `filterProductIds()` function returns an array of strings that represent the
   * `productId` property of each product in the `Map` object stored in the `products` property of the
   * current object. The function filters out any products that do not have a `productId` property and
   * then maps the remaining products to their `productId` values.
   */

  /**
   * Filters the product IDs from the products map and returns them as an array.
   * @returns {string[]} An array of product IDs.
   */
  public filterProductIds(): string[] {
    const ids = Array.from(this.products.keys())
      .filter((product) => product.productId)
      .map((product) => product.productId);
    return ids;
  }

  /**
   * Concatenates all coupon lists and returns a new array with unique values.
   * @returns {string[]} An array of unique coupon codes.
   */
  public concatCouponList = (): string[] => {
    const couponList: string[] = this.additionalCouponDiscount.concat(
      this.conditionalCouponDiscounts,
      this.bundleCouponDiscount,
      this.nonConditionalCouponDiscounts, // This coupon cannot be removed from the list
    );

    // Clean coupon list in case there are duplicated values or null values
    return [...new Set(couponList.filter((value) => value !== null && value !== undefined))];
  };

  /**
   * Clears the additional coupon discount list.
   * @returns {void}
   */
  public cleanAdditionalCouponList = () => {
    this.additionalCouponDiscount.length = 0;
    this.additionalCouponDiscount$.next(this.additionalCouponDiscount);
  };

  /**
   * Clears the default coupon discount list.
   * @returns {void}
   */
  public cleanDefaultCouponList = () => {
    this.conditionalCouponDiscounts.length = 0;
    this.conditionalCouponDiscounts$.next(this.additionalCouponDiscount);
  };

  /**
   * Checks if the provided coupon list includes an additional coupon discount and emits the response.
   * @param {CouponList[]} couponList - The list of coupons to check.
   * @returns {void}
   */
  public includesAdditionalCoupon = (couponList: CouponList[]): void => {
    let foundValidCoupon = false;
    let lastCouponInList = 0;

    // before establishing if a coupon is valid or not, make sure
    // the couponList has coupons to validate against an item
    if (!couponList.length) {
      return;
    }

    couponList.forEach((coupon, index) => {
      lastCouponInList = index;
      if (this.additionalCouponDiscount.includes(coupon.code)) {
        this.additionalCouponResponse$.next(coupon);
        foundValidCoupon = true;
      }
    });

    if (
      this.additionalCouponDiscount.length > couponList.length &&
      this.additionalCouponDiscount[lastCouponInList + 1]
    ) {
      this.hasAdditionalCoupon$.next(false);
      this.removeCoupon(this.additionalCouponDiscount[lastCouponInList + 1], true);
      return;
    }

    this.hasAdditionalCoupon$.next(foundValidCoupon);
  };

  /**
   * Sets the price of products in a shopping cart.
   * @param {LineItemList[]} lineItemList - An array of line items in the cart.
   */
  private setProductPrice = (lineItemList: LineItemList[]) => {
    lineItemList.forEach((item) => {
      const { product: xgritProduct, chargeAmount, couponList, hiddenModifierPrice } = item;

      this.includesAdditionalCoupon(couponList);

      this.products.forEach((value, product) => {
        if (product.productId === xgritProduct._id) {
          const mainProductPrice = this.hasReferralCoupon
            ? xgritProduct.pricing.current
            : this.xccEnv.brand.toUpperCase() !== Brand.ACE
            ? xgritProduct.pricing.current
            : xgritProduct.pricing.current > hiddenModifierPrice
            ? xgritProduct.pricing.current
            : hiddenModifierPrice;

          product.xgritData = {
            regularPrice: mainProductPrice,
            discountedPrice: chargeAmount,
            couponCodeList: couponList,
          };

          product.xgritData.couponCodeList.forEach((coupon) => {
            if (coupon.code === this.window.xccConfig.pageConfig.aarpConfig?.memberCouponCode) {
              coupon.uid = UidList.aarp;
              coupon.label = this.window.xccConfig.pageConfig.aarpConfig?.memberCouponCodeLabel;
            }
            if (this.conditionalCouponDiscounts.includes(coupon.code)) {
              coupon.label = this.setRSALabel();
              coupon.uid = UidList.rsaDiscount;
            } else if (this.nonConditionalCouponDiscounts.includes(coupon.code)) {
              // Set isDD to true so we can enable the DD discount label
              coupon.label = this.setRSALabel(true);
              coupon.uid = UidList.rsaDiscount;
            }

            /**
             * Get Ambassador Coupons if they are present in URL
             * and set the uid to ambassador if the coupon code matches
             * in the couponCodeList
             */
            const referralCodeWithRSA = this.route.snapshot.queryParamMap.get('couponAmbassadorRSA');
            const referralCodeWithoutRSA = this.route.snapshot.queryParamMap.get('couponAmbassador');
            if (coupon.code === referralCodeWithRSA || coupon.code === referralCodeWithoutRSA) {
              coupon.uid = UidList.referralCode;
            }
          });
        }
      });
    });
  };

  /**
   * Triggers the price update process. This function should be called whenever
   * there is a change in the product list or coupon codes that requires a recalculation
   * of the total price.
   */
  public updateProductPrices = (): void => {
    this.updatePricesTrigger.next();
  };

  /**
   * Defines the price update operation as an observable sequence. This observable
   * listens for signals from `updatePricesTrigger` and performs a switchMap operation,
   * which ensures that only the latest price update call is considered. The switchMap operator
   * cancels the previous request and subscribes to a new one, hence preventing outdated
   * data from affecting the cart's state.
   *
   * @type {Observable<void>}
   */
  public currentPriceUpdate: Observable<void> = this.updatePricesTrigger.pipe(
    skip(1), // Skip the initial trigger to avoid empty product list
    switchMap((): Observable<void> => {
      const productIdList = this.filterProductIds();
      const couponCodeList = this.concatCouponList();

      const params: PurchaseReceiptPriceParams = {
        productIdList,
        ...(couponCodeList.length && { couponCodeList }), // Include couponCodeList only if it's not empty
      };

      this.shoppingCartIsLoading = true;

      return this.xgritApiService.getPurchaseReceiptPrice(params).pipe(
        tap((xgritResponse: XgritPricingSuccessful) => {
          this.totalPriceDollarsSubject.next(xgritResponse.total);
          this.setProductPrice(xgritResponse.lineItemList);
          this.shoppingCartIsLoading = false;
        }),
        catchError((err: any): Observable<void> => {
          const errorMessage = err?.error ? JSON.stringify(err.error) : 'Unknown error';
          const errorCodes = [
            40402, // "We can't find that coupon." or "Coupon has expired."
            40000, // "User has already purchased the course"
            40200, // "There is a problem with user payment"
          ];

          const containsSpecificErrorCode = errorCodes.some((code) => errorMessage.includes(code.toString()));

          if (containsSpecificErrorCode) {
            console.warn('Handled specific error in price update:', errorMessage);
          } else {
            console.error('Error in price update:', errorMessage);
          }

          this.shoppingCartIsLoading = false;
          return of<void>(undefined);
        }),
        map((): void => {}),
      );
    }),
  );

  /**
   * This method is responsible for increasing a products quantity by the
   * specified amount. If the product already exists within the products
   * collection, its quantity will be increased by the specified amount. If it
   * does not already exist it will be added to the collection with a quantity of
   * the specified amount.
   * @param {Product} product - the product to increase the quantity of
   * @param {number} [quantity=1] - the amount to increase the quantity by
   * @param {boolean} [checkCoupon=true] - the boolean to perform or not the product as coupon validation
   * @throws {Error} - when product argument is nil
   * @throws {Error} - when quantity argument is less than 1
   */
  addProduct(product: Product, quantity = 1): void {
    if (quantity !== 1) {
      throw new Error(`Only 1 item per product is allowed ${quantity}`);
    }

    this.validateIsNotNil(product);
    this.validateIsGreaterThan(quantity, 0);

    const canToggleProductSet = !this.toggleableProductSet;
    const hasReplacementToggleUid = product.replacementToggleUid != null;
    const shouldEmitToggleable = canToggleProductSet && hasReplacementToggleUid;
    if (shouldEmitToggleable) {
      this.toggleableProductSubject.next(product);
      this.toggleableProductSet = true;
    }

    this.trackProductAdded(product);

    const hasProduct = this.products.has(product);
    if (hasProduct) {
      const newQuantity = this.products.get(product) + quantity;
      this.setAndBroadcast(product, newQuantity);
      return;
    }
    this.setAndBroadcast(product, quantity);
  }

  /**
   * This method is responsible for reducing product's quantity by the specified
   * amount. If the provided Product object does not exist within the products
   * collection, it will do nothing. If the quantity argument is excluded, the
   * product will be deleted from the products collection. If the value
   * of a products quantity is less than one after subtracting, it will be
   * deleted from the products collection.
   * @param {Product} product - the Product to reduce or remove.
   * @param {number} [quantity]
   *
   * @throws {Error} - when product argument is nil
   */
  removeProduct(product: Product, quantity?: number) {
    this.validateIsNotNil(product);
    const isMissing = !this.products.has(product);
    if (isMissing) {
      // todo: this should probably throw
      return;
    }

    const isQuantityNil = quantity == null;
    if (isQuantityNil) {
      this.deleteAndBroadcast(product);
      return;
    }

    const newQuantity = this.products.get(product) - quantity;
    const isQuantityLessThanZero = newQuantity <= 0;
    if (isQuantityLessThanZero) {
      this.deleteAndBroadcast(product);
      return;
    }
    this.setAndBroadcast(product, newQuantity);
  }

  /**
   * Finds the first product whose label matches the param
   * @param label - the product label to look up by
   * @throws {Error} - when no product can be returned
   */
  getProductByLabel(label: string): Product {
    const products = Array.from(this.products.keys());
    for (const prod of products) {
      if (prod.label === label) {
        return prod;
      }
    }
    throw new Error(`product with label: "${label}" was not found `);
  }

  isProductInCart(label: string): boolean {
    const products = Array.from(this.products.keys());
    return products.some((product) => product.label === label);
  }

  getMainProduct(): Product {
    const products = Array.from(this.products.keys());
    for (const prod of products) {
      /**
       * TODO: 'course' won't always be the main product. This should be refactored.
       * Maybe a new `type`, such as 'main-product'. Keep in mind that the main product
       * isn't static. It should have the flexibility to be replaced by any product,
       * like an add-on bundle, standalone RSA, permit exam, etc.
       */
      if (prod.uid === 'course' && !prod.type) {
        return prod;
      }
    }
    throw new Error(`main product was not found `);
  }

  private validateIsNotNil<T>(value: T) {
    const hasNilProduct = value == null;
    if (hasNilProduct) {
      throw new Error('argument cannot be nil');
    }
  }

  private validateIsGreaterThan(subject: number, criteria: number) {
    const isLessThanOrEqualToCriteria = subject <= criteria;
    if (isLessThanOrEqualToCriteria) {
      throw new Error('Value must be greater than: ' + criteria);
    }
  }

  private transformMapToArray = (productMap: Map<Product, number>): Product[] => {
    return Array.from(productMap.keys());
  };

  public broadcastProductCollectionChange = () => {
    this.productsSubject.next(this.products);
    this.updateProductPrices();
  };

  private deleteAndBroadcast(product: Product) {
    this.products.delete(product);
    this.broadcastProductCollectionChange();
  }

  private setAndBroadcast(product: Product, quantity: number) {
    this.products.set(product, quantity);
    this.broadcastProductCollectionChange();
  }

  private trackProductAdded(product: Product): void {
    const params: ProductAddedParams = {
      brand: this.xccEnv.brand.toUpperCase() === Brand.AA ? Brand.ACE : this.xccEnv.brand.toUpperCase(),
      coupon: this.route.snapshot.queryParamMap.get('coupon') || '',
      course_segment: this.segment,
      platform: 'MKT',
      quantity: 1,
      category: product.uid,
      name: product.label,
      price: parseFloat(product.customerPrice?.toString()) || 0,
      product_name: product.label,
      product_id: product.productId,
      course_id: this.courseId,
    };

    this.segmentTracking.callAnalyticsMethod('track', 'Product Added', params);
  }

  removeCoupon(code: string, silently = false): void {
    this.additionalCouponDiscount = this.additionalCouponDiscount.filter((coupon) => coupon !== code);
    if (!silently) {
      this.additionalCouponDiscount$.next(this.additionalCouponDiscount);
    }
  }
}
