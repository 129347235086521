import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import type {
  IterableSubscribeDataFields,
  IterableSuccessfulResponse,
  IterableTrackPurchase,
  IterableUpdateUserPurchase,
  PurchaseSuccessful,
} from '@xcc-models';
import { Brand, CourseTypes, XccApiEndpoints, XccWindow } from '@xcc-models';
import { TenantService } from './tenant.service';
import { XccEnvironment } from './xcc-environment';
import { BrandService } from '@xcc-client/services/lib/brand-service/brand.service';

@Injectable({
  providedIn: 'root',
})
export class IterableSubscribeService {
  constructor(
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly tenantService: TenantService,
    @Inject('window') private readonly window: XccWindow,
    @Inject('xccEnv') private readonly xccEnv: XccEnvironment,
    private readonly brandService: BrandService,
  ) {}

  trackPurchase = (purchaseResponse: PurchaseSuccessful): Observable<IterableSuccessfulResponse> => {
    const productIdCollection: string[] = purchaseResponse.lineItemList.map((item) => item.product._id);
    const productKeyCollection: string[] = purchaseResponse.lineItemList.map((item) => item.product.key);

    const resource = XccApiEndpoints.iterableTrack;
    const body: IterableTrackPurchase = {
      dataFields: {
        orderId: purchaseResponse.transactionId,
        coupon: this.route.snapshot.queryParamMap.get('coupon'),
        platform: 'web',
        purchasePrice: purchaseResponse.total,
        // List of product IDs separated by a pipe e.g. 1z45V4OWKu|zRMp1Lm1ceAXxsHZ|ZvgohA5gckaIlkB6
        productId: productIdCollection.join('|'),
        productKeyList: productKeyCollection.join('|'),
      },
      email: purchaseResponse.user.username,
      eventName: 'purchase',
      brandId: this.brandService.getBrandByIdForIterable(this.xccEnv.brand.toUpperCase()),
    };
    return this.postResource(resource, body);
  };

  /**
   * After a user makes a successful purchase, we must update the user in Iterable with info.
   */
  updateUserPurchase = (purchaseResponse: PurchaseSuccessful): Observable<IterableSuccessfulResponse> => {
    const body: IterableUpdateUserPurchase = {
      dataFields: {
        aceId: purchaseResponse.user._id,
        email: purchaseResponse.user.username,
        firstName: purchaseResponse.user.firstName,
        lastName: purchaseResponse.user.lastName,
        isPurchased: true,
        userType: 'student',
        platform: 'web',
      },
      email: purchaseResponse.user.username,
      brandId: this.brandService.getBrandByIdForIterable(this.xccEnv.brand.toUpperCase()),
    };
    return this.postResource(XccApiEndpoints.iterableUpdateUser, body);
  };

  /**
   * Subscribe the user to abandoned cart list.
   */
  subscribeUser = (
    userData: Partial<IterableSubscribeDataFields>,
    segment: string,
    isParent = false,
  ): Observable<IterableSuccessfulResponse> => {
    /**
     * A course type is required for the XCC API to subscribe the user to the correct list. If the
     * course type is not recognized (e.g. 'PT'), don't subscribe the user to abandoned cart.
     */
    let courseType: CourseTypes;
    /**
     * If brand is ACE we need to get the course type depending if type
     * is DD || DE, if we use the older `getCourseType` we can override values
     * since DEC/IDS uses the same course type but with different output
     *
     * @example
     *
        case 'DD':
          return CourseTypes.DIP;
        case 'DE':
          return CourseTypes.TEEN;
     *
     */
    if (this.xccEnv.brand.toUpperCase() === Brand.ACE) {
      courseType = this.getCourseTypeForAceable(segment);
    } else {
      courseType = this.getCourseType(segment);
    }

    if (!courseType) {
      return EMPTY;
    }
    const body: IterableSubscribeDataFields = {
      brandId: this.xccEnv.brand.toUpperCase(),
      email: userData.email,
      /**
       * Validate if form data comes from parentForm or studentForm
       * - StudentForm has `firstName` and `lastName`
       * - ParentForm has only `name`
       */
      firstName: userData.name ? userData.name : userData.firstName,
      lastName: userData.name ? userData.name : userData.lastName,
      userType: isParent ? 'parent' : 'student',
      checkoutUrl: this.window.location.href,
      courseState: userData.courseState,
      courseType,
      signupSource: 'ListAPI',
    };
    return this.postResource(XccApiEndpoints.iterableSubscribe, body);
  };

  /**
   * Aceable can have two or more product types (in the future) so we need
   * to get the courseType separatelly from the getCourseType function
   * @param courseType
   * @returns DE || DD (Aceable Course Type to be used with Iterable)
   */
  private getCourseTypeForAceable = (courseType: string): CourseTypes => {
    switch (courseType) {
      case CourseTypes.ADE:
        return CourseTypes.ADE;
      case CourseTypes.AMI:
        return CourseTypes.AMI;
      case CourseTypes.DD:
        return CourseTypes.DD;
      case CourseTypes.DE:
        return CourseTypes.DE;
      case CourseTypes.PTDE:
        return CourseTypes.PTDE;
      default:
        return CourseTypes.DE;
    }
  };

  private getCourseType = (segment: string): CourseTypes => {
    switch (segment) {
      case 'DD':
        return CourseTypes.DD;
      case 'ADE':
        return CourseTypes.ADE;
      case 'DE':
        return CourseTypes.DE;
      case 'MDS':
        return CourseTypes.MATURE;
      case 'BTW':
        return CourseTypes.BTW;
      case 'AA-RE':
        return CourseTypes.AA;
      case 'DIP':
        return CourseTypes.DD;
      case CourseTypes.PTDE:
        return CourseTypes.PTDE;
      case CourseTypes.PA:
        return CourseTypes.PA;
    }
  };

  private postResource = (
    resource: string,
    body: any, // eslint-disable-line
  ): Observable<IterableSuccessfulResponse> => {
    const url = this.tenantService.getUrlForResource(resource);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<IterableSuccessfulResponse>(url, body, httpOptions);
  };
}
