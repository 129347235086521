<div class="add-on" [class.add-on-selected]="rsaClaimStatus">
  <div class="grid add-on__item grid-flow-row gap-y-2 gap-x-4 md:gap-x-6">
    <button
      (click)="onToggleOption()"
      class="inline-block h-6 w-6 !bg-transparent p-0 hover:bg-transparent hover:border-0 mt-1"
    >
      <svg
        class="icon relative"
        *ngIf="!rsaClaimStatus"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
        <line x1="12" y1="7" x2="12" y2="17" stroke="currentColor" stroke-width="2" />
        <line x1="17" y1="12" x2="7" y2="12" stroke="currentColor" stroke-width="2" />
      </svg>
      <fa-icon class="icon" *ngIf="rsaClaimStatus" [icon]="faCheckCircle"></fa-icon>
    </button>
    <p class="font-extrabold !mb-0 add-on__title">
      {{ addon.title }}
    </p>
  </div>
  <p *ngIf="addon.subtitle" class="flex-grow p-small ml-10 md:ml-12 !mb-0">
    {{ addon.subtitle }}
  </p>
  <ul class="ml-10 md:ml-12 mt-4 list-none" *ngIf="addon.data && addon.data.bullets">
    <li *ngFor="let bullet of addon.data.bullets" class="upsell-description mb-4">
      {{ bullet }}
    </li>
  </ul>
</div>
