import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PostPurchaseService } from './aarp-postpurchase/aarp-postpruchase.service';
import { AarpPostPurchaseComponent } from './aarp-postpurchase/aarp-postpurchase.component';

@NgModule({
  declarations: [AarpPostPurchaseComponent],
  imports: [CommonModule],
  providers: [PostPurchaseService],
})
export class AarpPostPurchaseModule {}